<script setup lang="ts">
import { useStoreInfo } from '@/utils/useStoreInfo'
import Navbar from '@/components/main/Navbar.vue'
import { storeHeader } from '@/event/main/header/storeHeader'
import { watchersHeader } from '@/utils/watchers/header/watchersHeader'

const { title, classTitle, updateStore, storeData, testDay, testHour, testMinute } = useStoreInfo()

watchersHeader()

</script>

<template>
  <header>
    <section class="section-header" :class="storeHeader.classTitle">
      <h1 class="text-h1">{{ storeHeader.title }}</h1>
      <p v-if="storeHeader.text" class="text">{{ storeHeader.text }}</p>
    </section>

    <Navbar />
  </header>
</template>

<style scoped>
header {
  @apply w-full;
}

.section-header {
  @apply flex flex-col items-center;
  line-height: 1.5;
}

.content-site {
  @apply min-h-screen flex flex-col;
}

header {}

.text-h1 {
  @apply text-center;
}

.default-siege {
  @apply bg-amber-eronor px-3 py-2 flex justify-center text-gray-800;
}

.open-store {
  @apply bg-lime-eronor px-3 py-2 flex justify-center;
}

.close-store {
  @apply bg-red-eronor px-3 py-2 flex justify-center;
}

.text {
  @apply underline;
}
</style>
