import { computed, watch } from 'vue'
import orders from '../orders'

export const orderGet = computed(() => {
  const orderData = orders.state.orderData || []

  return orderData
    .map((order) => {
      const dateAdded = order.date_added?.date ? new Date(order.date_added.date) : null
      const dateModified = order.date_modified?.date ? new Date(order.date_modified.date) : null
      const orderDate = order.order_date?.date ? new Date(order.order_date.date) : null

      const formatDate = (date: Date | null) => {
        if (!date) return ''
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hour = String(date.getHours()).padStart(2, '0')
        const minute = String(date.getMinutes()).padStart(2, '0')
        return `${year}/${month}/${day} ${hour}h${minute}`
      }

      const formatOrderDate = (date: Date | null) => {
        if (!date) return ''
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}/${month}/${day}`
      }

      const isPaid = (order: any) => {
        const total = parseFloat(order.total)
        const totalNKT = parseFloat(order.total_NKT)
        const totalCC = parseFloat(order.total_CC)
        const totalTIC = parseFloat(order.total_TIC)

        if (
          (totalNKT > 0 && total === totalNKT) ||
          (totalCC > 0 && total === totalCC) ||
          (totalTIC > 0 && total === totalTIC)
        ) {
          return 'Réglé'
        }
        if (totalNKT + totalCC + totalTIC === total) {
          return 'Réglé'
        }

        return 'Non réglé'
      }

      return {
        ...order,
        total: parseFloat(order.total),
        date_added: formatDate(dateAdded),
        date_modified: formatDate(dateModified),
        order_date: formatOrderDate(orderDate),
        raw_date_added: dateAdded,
        payment_status: isPaid(order)
      }
    })
    .sort((a, b) => {
      const dateA = a.raw_date_added ? a.raw_date_added.getTime() : 0
      const dateB = b.raw_date_added ? b.raw_date_added.getTime() : 0
      return dateB - dateA
    })
})
