import { computed, ref, toRaw, watch } from 'vue'
import { numberBasket } from './basketBus'
import stores from '@/storage/stores'
import { formatStoreName } from '@/utils/store/formatStoreName'
import { dataLoaded } from './dataLoaded'

export interface SelectedProduct {
  optionName: string
  productName: string
  valueId: number
  option_id: number
  price: string
  type: string
  title: string
  sort_order: number
  reset: number
  product_option_id: number
}
export interface CartItem {
  category_id: number
  product_image: string
  product_id: number
  product: string
  price: number
  total_price: number
  price_tax: number
  rate_tax: number
  quantity: number
  lbl_printer_id: number
  printer_port: number
  printer_id: number
  product_option: SelectedProduct[]
}
export interface CartItemWithSoloPrice {
  total_price: number
  category_id: number
  product_image: string
  product_id: number
  product: string
  product_option: SelectedProduct[]
  price: number
  price_tax: number
  rate_tax: number
  quantity: number
  solo_price: string
}

export interface ProductOption {
  optionName: string
  productName: string
  valueId: number
  option_id: number
  price: string
  type: string
  title: string
  sort_order: number
  reset: number
  product_option_id: number
}

export interface BasketProduct {
  category_id: number
  product_id: number
  product: string
  product_image: string
  quantity: number
  price: number
  total_price: number
  price_tax: number
  rate_tax: string
  printer_id: number
  printer_port: number
  lbl_printer_id: number | null
  product_option: ProductOption[]
}

export const showCartBus = ref(false)
export const indexUpdateElement = ref<number | null>(null)

const savedCart = computed(() => {
  const store = stores.state.storeData.name
  if (store) {
    const storeName = formatStoreName(store)
    const savedData = localStorage.getItem(`${storeName}_cart`)
    if (savedData) {
      try {
        const parsedCart = JSON.parse(savedData)
        return parsedCart
      } catch (error) {
        return []
      }
    }
  }
  return []
})

watch(
  () => dataLoaded.value,
  (newValue) => {
    if (newValue === 2) {
      cartBus.value = savedCart.value
      numberBasket.value = cartBus.value.reduce((acc, item) => acc + item.quantity, 0)
    }
  }
)
export const cartBus = ref<CartItem[]>([])

watch(
  () => cartBus.value,
  (newValue) => {
    const store = stores.state.storeData.name

    if (store) {
      const storeName = formatStoreName(store)

      if (newValue && newValue.length > 0) {
        localStorage.setItem(`${storeName}_cart`, JSON.stringify(toRaw(cartBus.value)))
      } else {
        localStorage.removeItem(`${storeName}_cart`)
      }
    }

    numberBasket.value = newValue.reduce((acc, item) => acc + item.quantity, 0)
  },
  { deep: true }
)
