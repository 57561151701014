<script setup lang="ts">
import { cartBus, indexUpdateElement, showCartBus, type ProductOption } from '@/event/cartBus'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import ButtonUpdate from '@/components/button/ButtonUpdate.vue'
import ButtonClearBasket from '@/components/button/basket/ButtonClearBasket.vue'
import ButtonSubstract from '@/components/button/basket/ButtonSubstract.vue'
import ButtonLinkBasket from '@/components/button/basket/ButtonLinkBasket.vue'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import { totalPrice } from '@/utils/price/totalPrice'
import { URL_IMAGE } from '@/config/constants'
import ButtonValidateOrder from '../button/order/ButtonValidateOrder.vue'
import { toggleValidateOrderModal } from '@/utils/toggle/basket/toggleValidateOrderModal'
import { toggleCartDetails } from '@/utils/toggle/basket/toggleCartDetails'


const showClose = ref(false)
const showUpdate = ref<boolean[]>([])
const isDropdownOpen = ref<boolean[]>([])
const route = useRoute()

const toggleDropdown = (index: number) => {
  isDropdownOpen.value[index] = !isDropdownOpen.value[index]
}

const basketCart = computed(() => {
  const currentCartItems = cartBus.value;

  return currentCartItems.map((item) => {
    const sortedOptions = [...item.product_option].sort((a, b) => a.sort_order - b.sort_order);

    const groupedOptions = sortedOptions.reduce((acc: Record<string, ProductOption[]>, option) => {
      if (!acc[option.optionName]) {
        acc[option.optionName] = [];
      }
      acc[option.optionName].push(option);

      return acc;
    }, {});

    return {
      ...item,
      groupedOptions,
    };
  });
});

const handleDropdownState = () => {
  if (indexUpdateElement.value !== null && indexUpdateElement.value >= 0) {
    isDropdownOpen.value = isDropdownOpen.value.map(() => false)
    isDropdownOpen.value[indexUpdateElement.value] = true
  }
}

function isValidPrice(price: string) {
  const parsedPrice = Number(price);
  return !isNaN(parsedPrice) && parsedPrice > 0;
}

watch(
  () => route.params,
  () => {
    handleDropdownState()
  }
)
watch(() => indexUpdateElement.value, handleDropdownState)

watch(
  () => showCartBus.value,
  (newValue) => {
    showCartBus.value = newValue
  }
)

</script>

<template>
  <div v-if="showCartBus" class="cart-details">
    <div class="div-svg">
      <button class="" @mouseover="showClose = true" @mouseleave="showClose = false" @click="toggleCartDetails">
        <p class="text-close" v-if="showClose">Fermer</p>
        <svg class="svg-cross" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M16 8L8 16M8.00001 8L16 16" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    </div>
    <div v-if="basketCart && basketCart.length > 0">
      <div class="section-basket">
        <div v-for="(product, i) in basketCart" :key="i" class="div-basket">
          <!-- {{ element }} -->
          <div class="dropdown">
            <button :disabled="product.product_option.length === 0" @click="toggleDropdown(i)" class="dropdown-button">
              <img :src="`${URL_IMAGE}${product.product_image}`" alt="" class="image-basket" />
              <div>
                <h2 class="text-title">
                  {{ product.product }}
                </h2>
                <!-- Affiche le premier productName -->

                <div class="div-dropdown">
                  <p class="text-nowrap">X{{ product.quantity }}</p>
                  <p class="text-amber-eronor text-nowrap text-sm font-bold">
                    {{ product.total_price }} €
                  </p>
                </div>
              </div>
              <div class="min-w-10">
                <svg v-if="product.product_option.length > 0" class="svg-arrowbottom" viewBox="0 0 24 24"
                  :class="{ 'rotate-up': isDropdownOpen[i] }">
                  <path
                    d="M6.46967 8.96967C6.76256 8.67678 7.23744 8.67678 7.53033 8.96967L12 13.4393L16.4697 8.96967C16.7626 8.67678 17.2374 8.67678 17.5303 8.96967C17.8232 9.26256 17.8232 9.73744 17.5303 10.0303L12.5303 15.0303C12.3897 15.171 12.1989 15.25 12 15.25C11.8011 15.25 11.6103 15.171 11.4697 15.0303L6.46967 10.0303C6.17678 9.73744 6.17678 9.26256 6.46967 8.96967Z" />
                </svg>
              </div>
            </button>
            <div class="flex items-center gap-2 relative">
              <ButtonSubstract :index="i" />
              <ButtonUpdate class="" :product_id="product.product_id" :category_id="product.category_id" :index="i" />
            </div>
          </div>

          <div v-if="isDropdownOpen[i]" class="dropdown-content">
            <div>
              <div class="drowpdown-content-div">
                <!-- {{ product }} -->

                <div class="flex pt-1 flex-wrap" v-for="(options, ind, i) in product.groupedOptions" :key="i">
                  <div class="flex flex-wrap gap-1">
                    <h3 class="font-semibold">
                      {{ ind + ' : ' }}
                    </h3>
                    <div class="flex flex-wrap gap-1">
                      <div v-for="(option, index) in options" :key="index">
                        <p v-if="Number(index) !== Object.entries(option).length - 1">{{ option.productName }} <span
                            v-if="isValidPrice(option.price)">
                            ({{ parseFloat(option.price).toFixed(2) }} €)
                          </span>,</p>
                        <p v-else>{{ option.productName }} <span v-if="isValidPrice(option.price)">
                            ({{ parseFloat(option.price).toFixed(2) }} €)
                          </span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="div-basket-modify">
        <ButtonValidateOrder @click="toggleValidateOrderModal" />
        <ButtonLinkBasket />
        <ButtonClearBasket />
        <!-- <button>Accéder au panier</button> -->
      </div>
      <p class="text-total">Prix total du panier : {{ convertPriceToEuro(totalPrice) }} €</p>
    </div>
    <p v-else>Votre panier est vide</p>
  </div>
</template>

<style scoped>
.cart-details {
  @apply absolute z-30 flex flex-col items-center rounded-md p-1 -right-4 max-w-72 min-w-[19rem] bg-slate-eronor gap-1;
  @apply smoke:bg-black smoke:border smoke-white:border smoke-white:border-black smoke-white:bg-white;
}

.section-basket {
  @apply flex flex-col gap-3 max-h-[22rem] scroll-auto overflow-y-auto overflow-x-hidden scrollbar-thin px-1;
}

.scrollbar-thin {
  scrollbar-width: thin;

}

.div-svg {
  @apply w-full flex justify-end px-2 relative gap-x-36;
}

.div-svg button {
  @apply w-10 flex justify-center items-center;
}

.svg-cross {
  @apply w-10 stroke-white-eronor hover:stroke-limedark-eronor;
  @apply smoke-white:stroke-black
}

.text-close {
  @apply absolute bottom-9 -right-1 bg-slate-eronor px-2 py-1 rounded-lg border;
  @apply smoke-white:bg-white smoke-white:border-black
}

.div-basket {
  @apply flex flex-col items-center min-w-72 rounded-lg bg-slate-hover p-1 relative;
  @apply smoke-white:bg-white
}

.text-total {
  @apply text-amber-5-eronor text-center font-bold underline p-4;
}

.div-basket-modify {
  @apply flex flex-col flex-wrap items-center gap-2 px-1 pt-2 w-full;
}

/* .div-basket-modify .btn-link-basket {
  @apply py-[0.6rem] px-[0.85rem];
} */


.dropdown-content {
  @apply pb-3 min-w-full flex justify-between px-2 items-start relative text-sm;
}

.drowpdown-content-div {
  @apply flex flex-col;
}

.span-price-option {
  @apply text-red-700 font-semibold;
}

.dropdown {
  @apply w-full flex;
}

.div-dropdown {
  @apply flex items-center gap-2;
}

.dropdown-button {
  @apply relative w-full min-h-10 flex justify-between gap-2 items-center pl-2 rounded-lg hover:bg-slate-eronor;
}

.dropdown-button:hover .svg-arrowbottom {
  @apply fill-lime-eronor;
}

.text-title {
  @apply text-sm text-wrap text-left font-bold underline;
}

.image-basket {
  @apply max-w-20 max-h-[4.5rem];
}

.rotate-up {
  transform: rotate(180deg);
}

.svg-arrowbottom {
  @apply w-8 hover:fill-lime-eronor fill-white-eronor;
  transition: transform 0.5s ease;
}

@media screen and (min-width: 375px) {
  .cart-details {
    @apply right-0 max-w-[26rem] p-2;
  }
}

@media screen and (min-width: 450px) {
  .div-basket-modify {
    @apply flex-row justify-center;
  }

  .div-basket {
    @apply min-w-96 p-2;
  }
}
</style>
