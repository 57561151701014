import { theme } from '@/event/theme/theme'

export function setTheme(newTheme: any) {
  const racine = document.documentElement.classList
  if (racine.contains(theme.value) && newTheme === theme.value) {
    racine.remove(theme.value)
    theme.value = 'default'
  } else {
    racine.remove(theme.value)
    racine.add(newTheme)
    theme.value = newTheme
  }
}
