<script setup lang="ts">

import ButtonNextOrder from '@/components/button/order/ButtonNextOrder.vue'
import { storeIsOpen } from '@/event/closeBus'
import { updateFieldOrderData } from '@/event/order/data/orderData'
import { configDeliveryTime, type DeliveryTimeType } from '@/storage/setting/configDeliveryTime'
import { configOpeningHours } from '@/storage/setting/configOpeningHours'
import { type HoursType, type OpeningHoursType } from '@/storage/stores'
import { computed, onMounted, ref, watch, watchEffect, type Ref } from 'vue'

const props = defineProps({ isOpenInitial: String })
const emitContinue = defineEmits()
const withdraw = ref('')
const showButton = ref(false)
const dropdownSelectHoursOrder = ref(false)
const selectHourFirstPart: Ref<number | string> = ref('')
const selectMinuteFirstPart = ref<string>('')
const selectHourSecondPart = ref('')
const selectMinuteSecondePart = ref<string>('')

const forwardOrdering = ref()
const afterOpening = ref()
const beforeClosing = ref()
const timeRange = ref()

const messageErrorSelectHoursEmpty = {
  message: '',
  class: ''
}

const messageErrorSelectHours = ref(messageErrorSelectHoursEmpty)

const availableTimeFirstPart = ref<string[][]>([]);
const availableHoursFirstPart = ref<number[]>([]);
const availableTimeSecondPart = ref<string[][]>([]);
const availableHoursSecondPart = ref<number[]>([]);

function setIntervalOpeningHours(
  configOpeningHours: { value: OpeningHoursType },
  configDeliveryTime: { value: DeliveryTimeType }
) {
  availableTimeFirstPart.value = [];
  availableHoursFirstPart.value = [];
  availableTimeSecondPart.value = [];
  availableHoursSecondPart.value = [];

  const openingHours = configOpeningHours.value
  const deliveryTime = configDeliveryTime.value
  const currentDate = new Date()
  let currentHour = currentDate.getHours()
  let currentDay = currentDate.getDay()

  if (currentDay === 0) {
    currentDay = 7
  }

  let currentDayString = String(currentDay)

  function timeToMinutes(timeString: string) {
    const [hours, minutes] = timeString.split(":").map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      return NaN;
    }

    return hours * 60 + minutes;
  }

  function timeInHourAndMinute(minutes: number) {
    const hour = Math.floor(minutes / 60)
    let minute = minutes % 60
    const minuteFormatted = minute < 10 ? minute.toString().padStart(2, '0') : minute.toString()

    return [hour, minuteFormatted]
  }

  function addToAvailableTimes(hoursArray: { value: number[] }, timeArray: { value: string[][] }, hour: any, minute: any) {
    if (!hoursArray.value.includes(hour)) {
      hoursArray.value.push(hour);
    }
    if (!timeArray.value[hour]) {
      timeArray.value[hour] = [];
    }
    if (!timeArray.value[hour].includes(minute)) {
      timeArray.value[hour].push(minute);
    }
  }

  function setArrayInterval() {
    normalizedTodaysHours.forEach((pick: HoursType, index: number) => {
      const openTime = timeToMinutes(pick.open);
      let closeTime = timeToMinutes(pick.close);
      let currentTime = currentTimeMinutes;
      let prepTimeStart = openTime + Number(deliveryTime.after_opening);
      let prepTimeEnd = closeTime - Number(deliveryTime.before_closing);
      let timeAddedWithCurrentTime = prepTimeStart >= currentTime ? prepTimeStart : currentTime;
      let isHourReset = false;

      if (!firstPart.value) {
        if (
          timeAddedWithCurrentTime >= openTime + Number(deliveryTime.after_opening) &&
          timeAddedWithCurrentTime < prepTimeEnd
        ) {
          const [hour, minute] = timeInHourAndMinute(timeAddedWithCurrentTime);
          addToAvailableTimes(availableHoursFirstPart, availableTimeFirstPart, hour, minute);
        }

        while (timeAddedWithCurrentTime <= prepTimeEnd) {
          const [hour, minute] = timeInHourAndMinute(timeAddedWithCurrentTime);

          if (hour === currentHour) {
            addToAvailableTimes(availableHoursFirstPart, availableTimeFirstPart, hour, minute);
          } else {
            if (!isHourReset) {
              timeAddedWithCurrentTime = Number(hour) * 60;
              isHourReset = true;
              const [hourReset, minuteReset] = timeInHourAndMinute(timeAddedWithCurrentTime);
              addToAvailableTimes(availableHoursFirstPart, availableTimeFirstPart, hourReset, minuteReset);
            } else {
              addToAvailableTimes(availableHoursFirstPart, availableTimeFirstPart, hour, minute);
            }
          }

          timeAddedWithCurrentTime += Number(deliveryTime.time_range);
          if (timeAddedWithCurrentTime === prepTimeEnd) {
            const [hour, minute] = timeInHourAndMinute(timeAddedWithCurrentTime);
            addToAvailableTimes(availableHoursFirstPart, availableTimeFirstPart, hour, minute);
          }

          if (timeAddedWithCurrentTime >= prepTimeEnd) {
            firstPart.value = true;
            break;
          }
        }
      } else {
        if (
          timeAddedWithCurrentTime >= openTime + Number(deliveryTime.after_opening) &&
          timeAddedWithCurrentTime < prepTimeEnd
        ) {
          const [hour, minute] = timeInHourAndMinute(timeAddedWithCurrentTime);
          addToAvailableTimes(availableHoursSecondPart, availableTimeSecondPart, hour, minute);
        }

        while (timeAddedWithCurrentTime < prepTimeEnd) {
          timeAddedWithCurrentTime += Number(deliveryTime.time_range);
          const [hour, minute] = timeInHourAndMinute(timeAddedWithCurrentTime);

          if (timeAddedWithCurrentTime > currentTimeMinutes + Number(deliveryTime.time_range)) {
            addToAvailableTimes(availableHoursSecondPart, availableTimeSecondPart, hour, minute);
          }
        }
      }
    });
  }

  const pickupService =
    openingHours.pickup_service && openingHours.pickup_service.length > 0
      ? openingHours.pickup_service.map((item) => ({ ...item }))
      : openingHours.opening_hours.map((item) => ({ ...item }))

  const todaysHours = pickupService.filter((item) => item.days.includes(currentDayString))
  const normalizedTodaysHours = todaysHours.map((item) => ({ ...item }))

  const minutes = currentDate.getMinutes();
  const currentTimeString = `${currentDate.getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
  const firstPart = ref(false)
  const currentTimeMinutes = timeToMinutes(`${currentDate.getHours()}:${currentDate.getMinutes()}`)

  let firstPartTime: { openTime: number, closeTime: number } = { openTime: 0, closeTime: 0 };
  let secondPartTime: { openTime: number, closeTime: number } = { openTime: 0, closeTime: 0 };

  normalizedTodaysHours.forEach((item, index) => {
    const openTime = timeToMinutes(item.open);
    const closeTime = timeToMinutes(item.close);

    if (index === 0) {
      firstPartTime = { openTime: openTime, closeTime: closeTime };
    } else {
      secondPartTime = { openTime: openTime, closeTime: closeTime };
    }
  });

  if (deliveryTime.forward_ordering === 'anytime') {
    setArrayInterval()
  } else if (deliveryTime.forward_ordering === 'after_opening') {
    if (firstPartTime && currentTimeMinutes >= firstPartTime.openTime) {
      setArrayInterval()
    }
    if (secondPartTime && currentTimeMinutes >= secondPartTime.openTime) {
      setArrayInterval()
    }
  } else if (deliveryTime.forward_ordering === 'before_closing') {
    if (firstPartTime && (currentTimeMinutes < firstPartTime.openTime && currentTimeMinutes >= firstPartTime.closeTime)) {
      setArrayInterval()
    }

    if (secondPartTime && (currentTimeMinutes < secondPartTime.openTime && currentTimeMinutes >= secondPartTime.closeTime)) {
      setArrayInterval()
    }
  }
}

const availableMinutesForSelectedHour = computed(() => {
  if (
    selectHourFirstPart.value !== '' &&
    availableTimeFirstPart.value[Number(selectHourFirstPart.value)]
  ) {
    return availableTimeFirstPart.value[Number(selectHourFirstPart.value)]
  }
  return []
})

const availableMinutesForSelectedHourSecondPart = computed(() => {
  if (
    selectHourFirstPart.value !== '' &&
    availableTimeSecondPart.value[Number(selectHourFirstPart.value)]
  ) {
    return availableTimeSecondPart.value[Number(selectHourFirstPart.value)]
  }
  return []
})

watchEffect(() => {
  forwardOrdering.value = configDeliveryTime.value?.forward_ordering || null
  afterOpening.value = configDeliveryTime.value?.after_opening || null
  beforeClosing.value = configDeliveryTime.value?.before_closing || null
  timeRange.value = configDeliveryTime.value?.time_range || null
})

onMounted(() => {
  if (configOpeningHours.value && configDeliveryTime.value) {
    setIntervalOpeningHours(configOpeningHours, configDeliveryTime)
  }
})

watch(
  () => props.isOpenInitial,
  (newProps, oldProps) => {
    if (newProps === 'step1') {
      showButton.value = false
    }
    if (newProps === 'step2') {
      dropdownSelectHoursOrder.value = true
      showButton.value = true
    } else {
      dropdownSelectHoursOrder.value = false

    }
  }
)

function toggleDropdownSelectHoursOrder() {
  emitContinue('continue', { step: 'step2' })
  dropdownSelectHoursOrder.value = !dropdownSelectHoursOrder.value
}

function addSelectOrderData() {
  let deliveryTimeSelect

  if (selectHourFirstPart.value && selectMinuteFirstPart.value) {
    deliveryTimeSelect = `${selectHourFirstPart.value}:${selectMinuteFirstPart.value}`
  }

  if (withdraw.value == 'selectHours' || withdraw.value.length === 0 && configDeliveryTime.value.instant_ordering === '0') {
    updateFieldOrderData({
      customer_delivery_time: deliveryTimeSelect,
      delivery_time: deliveryTimeSelect,
      order_time: 'Today'
    })
  }
}

const onContinue = () => {
  const messageError = {
    message: '',
    class: 'error'
  }

  if (selectHourFirstPart.value !== 0 && selectHourSecondPart.value.length !== 0) {
    messageError.message = "Sélectionner une plage horaire"
    messageErrorSelectHours.value = messageError
    setTimeout(() => {
      messageErrorSelectHours.value = messageErrorSelectHoursEmpty
    }, 3000)
  } else if (
    (withdraw.value.length !== 0 && withdraw.value == 'now') ||
    (withdraw.value == 'selectHours' && typeof selectHourFirstPart.value === 'string' && selectHourFirstPart.value.length !== 0) ||
    (withdraw.value.length === 0 && configDeliveryTime.value.instant_ordering === '0'
      && typeof selectHourFirstPart.value === 'string'
      && selectHourFirstPart.value.length !== 0 && typeof selectMinuteFirstPart.value === 'string'
      && selectMinuteFirstPart.value.length !== 0)
  ) {
    emitContinue('continue', { step: 'step3' })
    dropdownSelectHoursOrder.value = false
    addSelectOrderData()
  } else {
    messageError.message = 'Veuillez faire un choix'
    messageErrorSelectHours.value = messageError
    setTimeout(() => {
      messageErrorSelectHours.value = messageErrorSelectHoursEmpty
    }, 3000)
  }
}

watch(selectHourFirstPart, () => {
  selectMinuteFirstPart.value = "";
})

watch(() => dropdownSelectHoursOrder.value, (newDropdown) => {
  if (newDropdown == true) {
    selectHourFirstPart.value = ''
    setIntervalOpeningHours(configOpeningHours, configDeliveryTime)
  }
})

</script>

<template>
  <section class="section-select-hours-order">
    <button class="button-select-hours-order"
      :class="{ isactive: dropdownSelectHoursOrder, 'rounded-md': !dropdownSelectHoursOrder }"
      @click="toggleDropdownSelectHoursOrder()" :disabled="!showButton">
      <div class="flex items-center">
        <svg viewBox="0 0 510 510" id="acorder" class="svg-clock-select"
          :class="{ isactive: dropdownSelectHoursOrder }">
          <path d="M267.75,12.75c-89.25,0-168.3,48.45-209.1,122.4L0,76.5v165.75h165.75
        l-71.4-71.4c33.15-63.75,96.9-107.1,173.4-107.1C372.3,63.75,459,150.45,459,255s-86.7,191.25-191.25,191.25
        c-84.15,0-153-53.55-181.05-127.5H33.15c28.05,102,122.4,178.5,234.6,178.5C402.9,497.25,510,387.6,510,255
        C510,122.4,400.35,12.75,267.75,12.75z M229.5,140.25V270.3l119.85,71.4l20.4-33.15l-102-61.2v-107.1H229.5z">
          </path>
        </svg>
        <h4 class="pl-5">Retrait</h4>
      </div>
      <svg class="svg-arrowbottom" viewBox="0 0 24 24" :class="{
        'rotate-up': dropdownSelectHoursOrder,
        isactive: dropdownSelectHoursOrder,
        hidden: !showButton
      }">
        <path
          d="M6.46967 8.96967C6.76256 8.67678 7.23744 8.67678 7.53033 8.96967L12 13.4393L16.4697 8.96967C16.7626 8.67678 17.2374 8.67678 17.5303 8.96967C17.8232 9.26256 17.8232 9.73744 17.5303 10.0303L12.5303 15.0303C12.3897 15.171 12.1989 15.25 12 15.25C11.8011 15.25 11.6103 15.171 11.4697 15.0303L6.46967 10.0303C6.17678 9.73744 6.17678 9.26256 6.46967 8.96967Z" />
      </svg>
    </button>

    <section v-if="dropdownSelectHoursOrder" class="section-content-select-hours">
      <label v-if="configDeliveryTime.instant_ordering === '1'" class="label-select-withdraw" for="now"><input
          type="radio" name="withdraw" v-model="withdraw" id="now" value="now" :disabled="!storeIsOpen" />
        <div class="flex flex-col">
          <p :class="{ 'line-through': !storeIsOpen }">
            Dès que possible
          </p>
          <p v-if="!storeIsOpen" :class="{ notOpen: !storeIsOpen }">
            (Le restaurant ne peut pas prendre votre commande pour le moment. Merci de la passer
            ultérieurement)
          </p>
        </div>
      </label>
      <label class="label-select-withdraw" for="selectHours"
        v-if="(availableTimeFirstPart.length > 0 || availableTimeSecondPart.length > 0) && configDeliveryTime.instant_ordering === '1'">
        <input type="radio" name="withdraw" v-model="withdraw" id="selectHours" value="selectHours" />
        Aujourd'hui
      </label>

      <div class="w-full text-center"
        v-if="withdraw == 'selectHours' || withdraw.length === 0 && configDeliveryTime.instant_ordering === '0'">

        <div class="div-select-hours">
          <label v-if="availableTimeFirstPart.length !== 0 || availableTimeSecondPart.length !== 0"
            for="delivery_time_first_part">
            <div class="label-hours-order">
              <p>Heures de récupération</p>
            </div>

            <div class="div-select-hours-input">
              <select v-model="selectHourFirstPart" name="delivery_time_first_part" id="delivery_time_first_part"
                class="select-delivery-time">
                <option value="" class="option-select-hours" selected disabled>Heure</option>

                <option v-for="(hour, index) in availableHoursFirstPart" :key="hour" :value="String(hour)"
                  class="option-select-hours">
                  {{ hour + ' h' }}

                </option>
                <option class="option-select-hours" :value="String(hour)"
                  v-for="(hour, index) in availableHoursSecondPart" :key="index">
                  {{ hour + ' h' }}
                </option>
              </select>
              <select v-model="selectMinuteFirstPart" name="delivery_time_first_part_minutes"
                id="delivery_time_first_part_minutes" class="select-delivery-time">
                <option value="" class="option-select-hours" selected disabled>Minutes</option>
                <option v-for="minute in availableMinutesForSelectedHour" :key="minute" :value="minute"
                  class="option-select-hours">
                  {{ minute }}
                </option>
                <option v-for="(minute, index) in availableMinutesForSelectedHourSecondPart" :key="index"
                  :value="minute" class="option-select-hours">
                  {{ minute }}
                </option>
              </select>


            </div>
          </label>
        </div>
      </div>

      <p v-if="messageErrorSelectHours.message.length !== 0" :class="messageErrorSelectHours.class">
        {{ messageErrorSelectHours.message }}
      </p>

      <div class="div-btn-continue-hours"
        v-if="availableTimeFirstPart.length !== 0 || availableTimeSecondPart.length !== 0 || configDeliveryTime.instant_ordering !== '0'">
        <ButtonNextOrder @click="onContinue" />
      </div>
      <div v-else class=" px-3 bg-red-eronor text-center rounded-md">
        <p>Vous ne pouvez plus commande pour aujourd'hui</p>
      </div>
    </section>
  </section>
</template>

<style scoped>
.section-select-hours-order {
  @apply bg-slate-eronor rounded-md;
}

.button-select-hours-order {
  @apply flex items-center gap-2 justify-between w-full py-3 pl-4 pr-2;
}

.svg-clock-select {
  @apply w-8 fill-white-eronor;
}

.svg-arrowbottom {
  @apply w-10 fill-white;
  transition: transform 0.5s ease;
}

.isactive {
  @apply fill-lime-eronor text-lime-eronor rounded-t-md bg-slate-hover;
}

.rotate-up {
  transform: rotate(180deg);
}

.button-select-hours-order:hover {
  @apply bg-slate-hover;
}

.button-select-hours-order:hover svg,
.button-select-hours-order:hover h4 {
  @apply fill-lime-eronor text-lime-eronor;
}

.section-content-select-hours {
  @apply w-full border-t py-2 flex flex-col items-center gap-1;
}

.label-select-withdraw {
  @apply flex items-baseline gap-3 w-full px-4;
}

.text-today {
  @apply text-red-eronor;
}

.notOpen {
  @apply text-red-eronor;
}

.div-select-hours {
  @apply max-w-full relative w-full px-4 pt-2;
}

.label-hours-order {
  @apply flex w-full flex-col;
}

.div-select-hours-input {
  @apply flex gap-3 justify-center;
}

.select-delivery-time {
  @apply w-full max-w-28 rounded-md text-slate-eronor text-wrap my-1 p-1;
}

.option-select-hours {
  @apply text-center max-h-40;
}

.error {
  @apply bg-red-eronor text-center w-full mt-1 px-1 py-2;
}

.div-btn-continue-hours {
  @apply w-full pt-2 pr-2 flex justify-end;
}

@media screen and (min-width: 400px) {
  .select-delivery-time {
    @apply w-80;
  }

  .section-content-select-hours {
    @apply px-8;
  }

  .error {
    @apply rounded-md;
  }
}

@media screen and (min-width: 700px) {
  .div-select-hours-input {
    @apply justify-start;
  }

  .label-hours-order {
    @apply items-start pl-10;
  }
}

@media screen and (min-width: 900px) {
  .div-select-hours {
    @apply pl-10;
  }
}
</style>
