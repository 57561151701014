import { conversionRateBus } from '@/event/conversionRateBus'
import { extraPricesBus } from '@/event/product/extraPricesBus'
import { quantityBus } from '@/event/product/quantityBus'

export const convertFinalPrice = (price: string | number) => {
  const priceNumber = typeof price === 'string' ? parseFloat(price) : price
  let convertedPrice = priceNumber * conversionRateBus.value * quantityBus.value

  if (extraPricesBus.value) {
    Object.values(extraPricesBus.value).forEach((value) => {
      Object.values(value).forEach((v) => {
        convertedPrice = convertedPrice + v * conversionRateBus.value * quantityBus.value
      })
    })
  }

  return parseFloat(convertedPrice.toFixed(2))
}
