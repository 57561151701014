<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
const showButton = ref(false)

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
const handleScroll = () => {
  showButton.value = window.scrollY > 20
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <button class="show-button-top" v-show="showButton" @click="scrollToTop">
    <svg class="svg-arrow-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="">
      <path d="M5 19L11.2929 12.7071C11.6834 12.3166 12.3166 12.3166 12.7071 12.7071L19 19" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 11L11.2929 4.70711C11.6834 4.31658 12.3166 4.31658 12.7071 4.70711L19 11" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </button>
</template>

<style scoped>
.show-button-top {
  @apply fixed bottom-10 right-10 bg-amber-eronor hover:shadow-sm hover:shadow-white z-50 p-3 rounded-md;
}

.show-button-top:hover {
  @apply bg-lime-eronor;
}

.show-button-top:hover .svg-arrow-top {
  @apply fill-lime-eronor stroke-white-eronor;
}

.svg-arrow-top {
  @apply w-4 fill-transparent stroke-slate-eronor;
}

@media screen and (min-width: 500px) {
  .show-button-top {
    @apply me-7;
  }
}

@media screen and (min-width: 800px) {
  .show-button-top {}
}

@media screen and (min-width: 1200px) {
  .show-button-top {
    @apply right-auto left-[21rem];
  }
}

@media screen and (min-width: 1500px) {
  .show-button-top {
    @apply left-[26rem];
  }
}

@media screen and (min-width: 1700px) {
  .show-button-top {
    @apply left-[32rem];
  }
}
</style>
