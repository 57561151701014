import { categoryIdBus } from '@/event/category/categoryIdBus'
import { productIdBus } from '@/event/product/productIdBus'
import { getElementFromCart } from './getElementFromCart'
import { quantityBus } from '@/event/product/quantityBus'
import { selectOptionsFromCart } from './selectOptionsFromCart'
import { updateProduct } from './updateProduct'
import { updateCategory } from './updateCategory'
import { productBus } from '@/event/product/productBus'
import { extractRelatedOptions } from './extractRelatedOptions'
import { useRouter, type RouteLocationNormalizedLoaded } from 'vue-router'
import products from '@/storage/products'
import router from '@/router'

export const updateValues = async (route: RouteLocationNormalizedLoaded) => {
  try {
    const { category_id, product_id } = route.params as { category_id: any; product_id: any }
    categoryIdBus.value = Number(category_id)
    productIdBus.value = Number(product_id)

    const categoryExists = products.state.productData.find(
      (v: { category_id: any }) => v.category_id === Number(categoryIdBus.value)
    )

    if (!categoryExists) {
      router.push('/')
    }

    const cartElement = getElementFromCart()
    if (cartElement) {
      quantityBus.value = cartElement.quantity
      selectOptionsFromCart(cartElement)
    } else {
      quantityBus.value = 1
    }

    await updateProduct()
    updateCategory()

    if (productBus.value) {
      extractRelatedOptions(productBus.value.options)
    }
  } catch (e) {}
}
