import { languageIdBus } from '@/event/languageIdBus'
import type { ValueOption } from '@/storage/products'
import { getOptionName } from './getOptionName'
import { selectionsBus } from '@/event/product/selectionsBus'
import { extraPricesBus } from '@/event/product/extraPricesBus'
import { relatedGroupBus } from '@/event/product/relatedGroupeBus'
import { getOptionNameById } from './getOptionNameById'

export const handleRadioClick = (value: ValueOption, option: any) => {
  const optionName = getOptionName(option, languageIdBus.value)

  if (!selectionsBus.value[optionName]) {
    selectionsBus.value[optionName] = []
  }
  if (!extraPricesBus.value[optionName]) {
    extraPricesBus.value[optionName] = {}
  }

  const currentSelections = selectionsBus.value[optionName]
  const exists = currentSelections.find((id: number) => id === Number(value.option_value_id))

  if (value.reset) {
    selectionsBus.value[optionName] = [Number(value.option_value_id)] 
    delete extraPricesBus.value[optionName] 
  } else {
    if (!exists) {
      currentSelections.push(Number(value.option_value_id))
      if (parseFloat(value.ov_price) !== 0) {
        extraPricesBus.value[optionName][value.option_value_id] = value.ov_price
      }

      const otherValue = option.values.find(
        (val: { option_value_id: any }) => val.option_value_id !== value.option_value_id
      )
      option.values.forEach((val: ValueOption) => {
        if (val.option_value_id !== value.option_value_id) {
          selectionsBus.value[optionName] = selectionsBus.value[optionName].filter(
            (id: number) => id !== val.option_value_id
          )
          delete extraPricesBus.value[optionName][val.option_value_id] 
        }
      })

      if (value.related_option_id && Array.isArray(value.related_option_id)) {
        value.related_option_id.forEach((id: number) => {
          const index = relatedGroupBus.value.indexOf(id)
          if (index > -1) {
            relatedGroupBus.value.splice(index, 1) 
          }
        })
      }

      option.values.forEach((v: ValueOption) => {
        if (
          v.option_value_id !== value.option_value_id &&
          v.related_option_id &&
          Array.isArray(v.related_option_id)
        ) {
          v.related_option_id.forEach((id: number) => {
            if (!relatedGroupBus.value.includes(id)) {
              relatedGroupBus.value.push(id)

              const optionNameById = getOptionNameById(id, languageIdBus.value)
              if (selectionsBus.value[optionNameById]) {
                if (selectionsBus.value[optionNameById].length > 0) {
                  selectionsBus.value[optionNameById] = []
                }
              }
            }
          })
        }
      })
    } else {
      selectionsBus.value[optionName] = currentSelections.filter(
        (id: number) => id !== value.option_value_id
      )

      delete extraPricesBus.value[optionName] 
      selectionsBus.value[optionName] = selectionsBus.value[optionName].filter(
        (id: any) => id !== value.option_value_id
      )

      option.values.forEach((v: ValueOption) => {
        if (v.related_option_id && Array.isArray(v.related_option_id)) {
          v.related_option_id.forEach((id: number) => {
            if (!relatedGroupBus.value.includes(id)) {
              relatedGroupBus.value.push(id)

              const optionNameById = getOptionNameById(id, languageIdBus.value)
              if (selectionsBus.value[optionNameById]) {
                if (selectionsBus.value[optionNameById].length > 0) {
                  selectionsBus.value[optionNameById] = []
                }
              }
            }
          })
        }
      })
    }
  }
}
