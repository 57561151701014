import { ref, watch } from 'vue'

export const theme = ref('default')

watch(theme, (newTheme) => {
  // Si `theme` n'est pas valide, on le remet à 'default'
  if (!['default', 'smoke', 'smoke-white'].includes(newTheme)) {
    theme.value = 'default'
  }
})
