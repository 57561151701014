<script setup lang="ts">
import { selectedOrder } from '@/event/account/dashboard/history/selectedOrder'

const getPaidClass = (order: any) => {
  return order.payment_status === 'Réglé' ? 'success' : 'error'
}

const getUniqueOptions = (options: any[]) => {
  const groupedOptions: Record<string, Set<string>> = {}

  options.forEach((option) => {
    if (!groupedOptions[option.name]) {
      groupedOptions[option.name] = new Set()
    }
    groupedOptions[option.name].add(option.value)
  })

  return Object.entries(groupedOptions).map(([name, values]) => ({
    name,
    values: Array.from(values)
  }))
}

function verifyIsPaid(paymentMethod: string, paymentStatus: string) {
  if (paymentStatus === 'Réglé' && paymentMethod === 'Non Payé') {
    return 'Payé';
  }
  return paymentMethod
}
function statusOrder(orderStatus: number) {
  if (orderStatus === 1 || orderStatus === 12) {
    return 'En attente'
  }

  if (orderStatus === 4) {
    return 'Annuler'
  }

  if (orderStatus === 2) {
    return 'Préparation'
  }

  if (orderStatus === 5) {
    return 'Approuvé'
  }

  if (orderStatus === 3) {
    return 'Pret'
  }

  if (orderStatus === 6) {
    return 'Remise'
  }
}
</script>

<template>
  <section v-if="selectedOrder" class="section-modal-order">
    <section v-if="selectedOrder" class="flex flex-col gap-1">
      <div class="div-content-modal">
        <p class="text-modal-order ">Commande : {{ selectedOrder.invoice_no }}</p>
        <p class="text-modal-order ">
          Total :
          <span class="text-lime-eronor">{{ selectedOrder.total }} €</span>
        </p>

      </div>

      <div>
        <p>{{ selectedOrder.date_added }}</p>
        <p class="text-modal-order ">Id° {{ selectedOrder.order_id }}</p>
      </div>

      <div class="div-content-modal">
        <p>{{ verifyIsPaid(selectedOrder.payment_method, selectedOrder.payment_status) }}</p>
        <p>{{ selectedOrder.shipping_method }}</p>
      </div>

      <div class="div-content-modal">
        <div>
          <p :class="{
            'wait-class': selectedOrder.order_status_id === 1 || selectedOrder.order_status_id === 12 || selectedOrder.order_status_id === 4,
            'preparation-class': selectedOrder.order_status_id === 2 || selectedOrder.order_status_id === 5,
            'ready-class': selectedOrder.order_status_id === 3,
            'delivery-class': selectedOrder.order_status_id === 6
          }">{{ statusOrder(selectedOrder.order_status_id) }}</p>
        </div>
        <div>
          <p :class="getPaidClass(selectedOrder)">{{ selectedOrder.payment_status }}</p>
        </div>
      </div>

      <section class="border-t mt-2 pt-2 flex flex-col gap-1">
        <div v-for="(order, index) in selectedOrder.order_products" :key="index" class="pb-2">
          <div class="flex justify-between w-full">
            <div class="flex gap-1">
              <p class="font-semibold underline">{{ order.name }}
              </p>
              <p>X {{ order.quantity }}</p>
            </div>

            <p class="text-lime-eronor">{{ parseFloat(order.total).toFixed(2) }} €</p>

          </div>
          <div v-for="(option, i) in getUniqueOptions(order.order_options)">
            <div class="flex gap-1">
              <p class="text-nowrap">{{ option.name }} :</p>
              <div class="flex">
                <p class="">
                  <span v-for="(value, ind) in option.values" :key="ind">
                    {{ value }}<span v-if="ind < option.values.length - 1">, </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<style scoped>
.section-modal-order {
  @apply flex flex-col gap-2 bg-slate-eronor z-20 min-w-[19rem] max-w-[35rem] rounded-md mx-2 p-2 fixed max-h-[32rem] overflow-y-auto;
}

.div-content-modal {
  @apply flex justify-between;
}

.success {
  @apply bg-lime-eronor rounded-full py-1 px-2;
}

.error {
  @apply bg-red-eronor rounded-full py-1 px-2;
}

.wait-class {
  @apply bg-red-eronor px-2 p-1 rounded-full flex
}

.preparation-class {
  @apply bg-amber-eronor px-2 p-1 rounded-full flex
}

.ready-class {
  @apply bg-blue-500 px-2 p-1 rounded-full flex
}

.delivery-class {
  @apply bg-lime-eronor px-2 p-1 rounded-full flex
}

.text-modal-order {
  @apply flex gap-1 font-bold text-slate-300 text-lg
}
</style>
