<script setup lang="ts">
import { selectedOrder } from '@/event/account/dashboard/history/selectedOrder'
import { showSelectedOrder } from '@/event/account/dashboard/history/showSelectedOrder'
import { orderGet } from '@/storage/order/orderGet'
import orders from '@/storage/orders'
import stores from '@/storage/stores'
import type { Socket } from 'socket.io-client'
import { computed, inject, onMounted, ref, watch } from 'vue'

const visibleCount = ref(6)
const mostRecentOrderClass = ref('');
const mostRecentOrderText = ref('');

const mostRecentOrder = computed({
  get() {
    if (!orderGet.value || orderGet.value.length === 0) {
      return null;
    }

    const today = new Date();
    const todayString = today.toISOString().split('T')[0];

    const normalizeDate = (dateString: any) => {
      const match = dateString.match(/^(\d{4})\/(\d{2})\/(\d{2}) (\d{2})h(\d{2})$/);
      if (match) {
        const [_, year, month, day, hour, minute] = match;
        return `${year}-${month}-${day}T${hour}:${minute}:00`;
      }
      return dateString;
    };

    const todayOrders = orderGet.value.filter(order => {
      const normalizedDate = normalizeDate(order.date_added);
      const orderDate = new Date(normalizedDate);
      const orderDateString = orderDate.toISOString().split('T')[0];
      return orderDateString === todayString;
    });

    if (todayOrders.length === 0) {
      return null;
    }

    const mostRecent = todayOrders.reduce((mostRecent, currentOrder) => {
      const mostRecentDate = new Date(normalizeDate(mostRecent.date_added));
      const currentOrderDate = new Date(normalizeDate(currentOrder.date_added));
      return currentOrderDate > mostRecentDate ? currentOrder : mostRecent;
    });


    if (mostRecent) {
      updateMostRecentOrderClass(mostRecent.order_status_id);
      updateMostRecentOrderText(mostRecent.order_status_id);
    }
    return mostRecent
  },

  set(newStatusId) {

    const mostRecent: any = mostRecentOrder.value;
    if (!mostRecent) {
      return;
    }


    mostRecent.order_status_id = newStatusId;


    updateMostRecentOrderClass(mostRecent.order_status_id);
    updateMostRecentOrderText(mostRecent.order_status_id);

    return mostRecent;
  }

});

const updateMostRecentOrderClass = (orderStatusId: number) => {
  if (orderStatusId === 1 || orderStatusId === 12 || orderStatusId === 4) {
    mostRecentOrderClass.value = 'wait-class';
  } else if (orderStatusId === 2) {
    mostRecentOrderClass.value = 'preparation-class';
  } else if (orderStatusId === 5) {
    mostRecentOrderClass.value = 'approve-class'
  } else if (orderStatusId === 3) {
    mostRecentOrderClass.value = 'ready-class';
  } else if (orderStatusId === 6) {
    mostRecentOrderClass.value = 'delivery-class';
  } else {
    mostRecentOrderClass.value = '';
  }
};


const updateMostRecentOrderText = (orderStatusId: number) => {
  if (orderStatusId === 1 || orderStatusId === 12) {
    mostRecentOrderText.value = 'En attente';
  } else if (orderStatusId === 4) {
    mostRecentOrderText.value = 'Annulé';
  } else if (orderStatusId === 2) {
    mostRecentOrderText.value = 'Préparation';
  } else if (orderStatusId === 5) {
    mostRecentOrderText.value = 'Approuvé';
  } else if (orderStatusId === 3) {
    mostRecentOrderText.value = 'Prêt';
  } else if (orderStatusId === 6) {
    mostRecentOrderText.value = 'Remise';
  } else {
    mostRecentOrderText.value = '';
  }
};

const visibleOrders = computed(() => {
  if (!orderGet.value || orderGet.value.length === 0) {
    return [];
  }
  const recentOrderId = mostRecentOrder.value ? mostRecentOrder.value.order_id : null;

  return orderGet.value.filter(order => order.order_id !== recentOrderId)
    .slice(0, visibleCount.value);
});

const loadMoreOrders = () => {
  visibleCount.value += 4
}

function lessOrders() {
  visibleCount.value -= 4
}

function show(order: any) {
  showSelectedOrder.value = true
  selectedOrder.value = order
}

const numberTest = 10

function verifyIsPaid(paymentMethod: string, paymentStatus: string) {
  if (paymentStatus === 'Réglé' && paymentMethod === 'Non Payé') {
    return 'Payé';
  }
  return paymentMethod
}

function statusOrder(orderStatus: number) {
  if (orderStatus === 1 || orderStatus === 12) {
    return 'En attente'
  }

  if (orderStatus === 4) {
    return 'Annuler'
  }

  if (orderStatus === 2) {
    return 'Préparation'
  }

  if (orderStatus === 5) {
    return 'Approuvé'
  }

  if (orderStatus === 3) {
    return 'Pret'
  }

  if (orderStatus === 6) {
    return 'Remise'
  }
}

function verifyDeliveryTime(order: any) {
  const orderStatusExclude = [1, 12, 9, 6, 4]

  if (orderStatusExclude.includes(order.order_status)) {
    return { delivery_time: '', class: '' }
  }

  return { delivery_time: `${order.delivery_time}`, class: 'success' }
}


onMounted(() => {
  const socket = inject('socket') as Socket
  if (socket) {
    socket.emit('create', stores.state.storeData.store_id);

    const order_id = mostRecentOrder.value?.order_id

    socket.emit('join', order_id);
    socket.on('orderStatus', (data) => {
      if (data.store_id === stores.state.storeData.store_id) {
        mostRecentOrder.value = data.order.order_info.order_status_id

      }
    })










  }
})

watch(mostRecentOrder, (newOrder) => {
  if (newOrder) {

    updateMostRecentOrderClass(newOrder.order_status_id);
    updateMostRecentOrderText(newOrder.order_status_id);
  }
});
</script>

<template>
  <section class="section-history">
    <section v-if="mostRecentOrder" class="section-last-order">
      <h4 class="font-semibold">Commande la plus récente</h4>
      <button @click="show(mostRecentOrder)" class="button-other-order">
        <div class=" div-other-order-first-content">
          <div class="flex flex-col">
            <div class="div-other-order-text-important text-lg">
              <p>Commande : </p>
              <p>{{ mostRecentOrder.invoice_no }}</p>
            </div>

            <p class="flex">{{ mostRecentOrder.date_added }}</p>

            <div class="div-other-order-text-important">
              <p>Id°</p>
              <p>{{ mostRecentOrder.order_id }}</p>
            </div>
          </div>

          <div class="div-other-order-text-important text-lg">
            <p>Total :</p>
            <p class="text-lime-eronor">{{ mostRecentOrder.total }} €</p>
          </div>
        </div>

        <div class=" div-other-order-second-content">
          <div v-if="verifyDeliveryTime(mostRecentOrder).class" class="flex whitespace-pre-wrap">
            <p>Heure de récupération : </p>
            <span class="" :class="verifyDeliveryTime(mostRecentOrder).class">{{
              verifyDeliveryTime(mostRecentOrder).delivery_time }}</span>
          </div>

          <div class="flex justify-between w-full ">
            <div>
              <p :class="mostRecentOrderClass">{{ mostRecentOrderText }}</p>
            </div>

            <div>
              <p v-if="mostRecentOrder.payment_status == 'Réglé'" class="is-set">
                {{ mostRecentOrder.payment_status }}
              </p>
              <p v-else class="not-set">{{ mostRecentOrder.payment_status }}
              </p>
            </div>
          </div>
        </div>
      </button>
    </section>

    <section v-if="visibleOrders.length > 0" class="w-full flex flex-col items-center gap-1">
      <h4 class="font-semibold">Autres commandes</h4>

      <div class="div-content-hitstory-other-order ">
        <button class="button-other-order min-h-full" v-for="(o, i) in visibleOrders" :key="i" @click="show(o)"
          :class="{ 'grid-span-full': i + 1 === visibleOrders.length && (i + 1) % 2 !== 0 }">

          <div class="div-other-order-first-content">
            <div class="flex flex-col">
              <div class="div-other-order-text-important  text-lg">
                <p>Commande : </p>
                <p>{{ o.invoice_no }}</p>
                <!-- <p>1000</p> -->
              </div>

              <p class="flex">{{ o.date_added }}</p>

              <div class="div-other-order-text-important">
                <p>Id°</p>
                <p>{{ o.order_id }}</p>
              </div>
            </div>

            <div class="div-other-order-text-important text-lg">
              <p>Total:</p>
              <p class="text-lime-eronor">{{ o.total }} €</p>
            </div>
          </div>

          <div class=" div-other-order-second-content">
            <div v-if="verifyDeliveryTime(o).class" class="flex whitespace-pre-wrap">
              <p>Heure de récupération : </p>
              <span :class="verifyDeliveryTime(o).class">{{ verifyDeliveryTime(o).delivery_time }}</span>
            </div>

            <div class="flex justify-between w-full ">
              <div>
                <p :class="{
                  'wait-class': o.order_status_id === 1 || o.order_status_id === 12 || o.order_status_id === 4,
                  'preparation-class': o.order_status_id === 2 || o.order_status_id === 5,
                  'ready-class': o.order_status_id === 3,
                  'delivery-class': o.order_status_id === 6
                }">{{ statusOrder(o.order_status_id) }}</p>
              </div>

              <div>
                <p v-if="o.payment_status == 'Réglé'" class="is-set">
                  {{ o.payment_status }}
                </p>
                <p v-else class="not-set">{{ o.payment_status }}</p>
              </div>
            </div>
          </div>
        </button>
      </div>
    </section>

    <div v-else class="pt-5">L'historique de commandes est vide</div>
    <button v-if="orders.getOrderData().length > 6 && visibleOrders.length < orderGet.length - 1"
      @click="loadMoreOrders"
      class="bg-lime-eronor hover:bg-limedark-eronor hover:shadow-sm hover:shadow-white text-white px-4 py-2 rounded-full mt-4 self-center">
      Afficher plus
    </button>
    <button v-if="visibleCount == 10" @click="lessOrders"
      class="bg-lime-eronor hover:bg-limedark-eronor hover:shadow-sm hover:shadow-white text-white px-4 py-2 rounded-full mt-4 self-center">
      Afficher moins
    </button>
  </section>
</template>

<style scoped>
.section-history {
  @apply bg-slate-eronor min-h-96 p-2 rounded-md flex flex-col items-center;
}

.section-last-order {
  @apply p-1 flex flex-col items-center w-full gap-2
}

.div-last-order {
  @apply mt-2 p-2 min-w-full flex flex-col border rounded-md gap-1 hover:border-l-lime-eronor
}

.span-status {
  @apply mx-2 inline-flex;
}

.div-order-for {
  @apply min-w-full min-h-full flex flex-col border p-2 rounded-md gap-1 hover:border-lime-eronor;
}

.wait-class {
  @apply bg-red-eronor px-2 p-1 rounded-full flex
}

.approve-class {
  @apply bg-amber-eronor px-2 p-1 rounded-full flex
}

.preparation-class {
  @apply bg-blue-500 px-2 p-1 rounded-full flex
}

.ready-class {
  @apply bg-lime-eronor px-2 p-1 rounded-full flex
}

.delivery-class {
  @apply bg-gray-500 px-2 p-1 rounded-full flex
}

.div-row {
  @apply flex justify-between w-full flex-wrap gap-1;
}

.div-content-history {
  @apply w-full p-1 flex flex-col items-center gap-2;
}

.grid-span-full {
  grid-column: span 2;

}

.warning {
  @apply flex text-red-eronor rounded-lg font-bold
}

.success {
  @apply flex text-lime-eronor rounded-lg font-bold
}



.button-other-order {
  @apply flex flex-col w-full border rounded-md justify-between hover:border-lime-eronor p-2 hover:scale-[1.01]
}

.div-other-order-first-content {
  @apply w-full flex items-start flex-wrap justify-between
}

.div-other-order-text-important {
  @apply flex gap-1 font-bold text-slate-300
}

.div-other-order-second-content {
  @apply flex flex-col min-h-full w-full gap-1
}

.is-set {
  @apply flex items-center bg-lime-eronor p-1 rounded-full
}

.not-set {
  @apply flex items-center bg-red-eronor p-1 rounded-full
}

.div-content-hitstory-other-order {
  @apply w-full p-1 flex flex-col items-center gap-2
}

@media screen and (min-width: 900px) {
  .div-content-history {
    @apply grid grid-cols-2;
  }

  .div-content-hitstory-other-order {
    @apply grid grid-cols-2
  }
}
</style>
