<script setup lang="ts">
import products, { type Product } from '@/storage/products'
import { URL_IMAGE } from '@/config/constants'
import { defineAsyncComponent, nextTick, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import stores from '@/storage/stores'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import { hasDescriptionForLanguage } from '@/utils/product/hasDescriptionForLanguage'
import { getCategoryName } from '@/utils/category/category'
import { categoryNameBus } from '@/event/category/categoryNameBus'
import { getProductName } from '@/utils/product/getProductName'
import { getProductDescription } from '@/utils/product/getProductDescription'
import { useCatalogProductWatchers } from '@/utils/watchers/catalogProduct/useCatalogProductWatchers'
import IconPlaceholder from '@/components/icons/iconEronor/IconPlaceholder.vue'

const Ariane = defineAsyncComponent(() => import('@/components/ariane/Ariane.vue'));


const route = useRoute()
const router = useRouter()

const categoryId = ref(route.params.category_id)
const categoryNameParam = route.params.category_name

const catalogProduct = ref<Product[]>(products.state.productData)
const languageId = ref<number>(3)
const catalogueBase = ref(stores.state.storeData.category)

const product = ref()

categoryNameBus.value = categoryNameParam
const SHIPPING_OPTIONS = [2, 3]

const categoryExists = catalogProduct.value.find(
  (v: { category_id: any }) => v.category_id === Number(categoryId.value)
);


if (!categoryExists) {
  router.push('/');
}

product.value = catalogProduct.value.filter(
  (product: { category_id: number, shipping: number }) =>
    product.category_id === Number(categoryId.value) && SHIPPING_OPTIONS.includes(product.shipping)
)

const updateProductAnimationDelays = () => {
  nextTick(() => {
    const allProducts = document.querySelectorAll('.product') as NodeListOf<HTMLElement>;
    allProducts.forEach(productElement => {
      productElement.classList.remove('enter');
      productElement.style.transition = 'none';
    });

    product.value.forEach((_: any, index: number) => {
      const productElement = document.querySelectorAll('.product')[index] as HTMLElement;
      if (productElement) {
        productElement.style.transition = '';

        setTimeout(() => {
          productElement.classList.add('enter');
        }, index * 100);
      }
    });
  });
};

const filterProductsByCategory = (categoryId: string | string[]) => {
  product.value = catalogProduct.value.filter(
    (product: { category_id: number, shipping: number }) => product.category_id === Number(categoryId) && SHIPPING_OPTIONS.includes(product.shipping)
  )
  nextTick(() => {
    updateProductAnimationDelays()
  })
}
filterProductsByCategory(categoryId.value)


useCatalogProductWatchers(
  catalogueBase,
  catalogProduct,
  categoryId,
  categoryNameBus,
  filterProductsByCategory
)

watch(() => categoryId, (newCategoryId, oldCategoryId) => {
  const categoryExists = catalogProduct.value.find(
    (v: { category_id: any }) => v.category_id === Number(newCategoryId)
  );

  if (!categoryExists) {
    router.push('/');
  }
})

</script>

<template class="">
  <section class="catalog-product">
    <Ariane />

    <aside class="aside-section">
      <div class="aside-div-category">
        <div v-for="category in catalogueBase" :key="category.category_id" class="aside-category-item"
          :class="{ 'category-isactive': category.category_id == Number(categoryId) }">
          <router-link v-if="category.category_id == Number(categoryId)" class="category-link isactive" :to="{
            name: 'catalog-product',
            params: {
              category_id: category.category_id,
              category_name: getCategoryName(category)
            }
          }">
            <div class="">
              {{ getCategoryName(category) }}
            </div>
          </router-link>
          <router-link v-else class="category-link" :to="{
            name: 'catalog-product',
            params: {
              category_id: category.category_id,
              category_name: getCategoryName(category)
            }
          }">
            <div class="">
              {{ getCategoryName(category) }}
            </div>
          </router-link>
        </div>
      </div>
      <section class="section-mobile-first">
        <div class="catalog-layout">
          <div class="catalog" v-if="product.length > 0">
            <div v-for="item in product" :key="item.model" class="">
              <router-link class="product" :to="{
                name: 'product',
                params: {
                  category_id: categoryId,
                  product_id: item.product_id
                }
              }">
                <img class="img" :src="`${URL_IMAGE}${item.image}`" :alt="item.model" loading="lazy" />

                <div class="text-content">
                  <h2>{{ getProductName(item) }}</h2>

                  <p class="truncate" v-if="hasDescriptionForLanguage(item.description, languageId)">
                    {{ getProductDescription(item) }}
                  </p>
                  <!-- {{ item.sort_order }} -->
                  <p>Prix: {{ convertPriceToEuro(item.price) }} €</p>
                </div>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div class="placeholder-product">
              <div class="placeholder-image" v-for="n in 6" :key="n">
                <IconPlaceholder />
              </div>
            </div>
          </div>
        </div>
      </section>
    </aside>
  </section>
</template>

<style scoped>
.placeholder-product {
  @apply grid grid-cols-2 gap-2 my-2;
}


/* Style pour la div contenant les catégories */
.aside-div-category {
  @apply hidden;
}



.aside-category-item .category-link {
  @apply hover:text-lime-eronor w-full text-center p-2;
}

.isactive {
  @apply text-lime-eronor;
}

/* fil-ariane */

.fil {
  @apply border-s px-3;
}

.fil:hover {
  @apply text-lime-eronor;
}

.ariane-div {
  @apply w-full bg-slate-eronor rounded-md flex items-center gap-3 px-3 py-2 mb-3 mt-3;
}

.svg-home {
  @apply w-6 fill-white-eronor;
}

.svg-home:hover {
  @apply fill-lime-eronor;
}

/* catalog product */
.catalog-product {
  @apply px-3 w-full;
}

.img {
  @apply object-contain w-full rounded-xl max-h-40;
}

.product {
  @apply flex flex-col items-center justify-center bg-slate-eronor rounded-xl relative py-2 h-full;
  @apply transition-transform duration-300 ease-in-out;
  opacity: 0;
  /* Par défaut les produits sont invisibles */
  transform: translateX(100%)
}

.product.enter {
  opacity: 1;
  transform: translateX(0);
}

.product:hover {
  @apply bg-slate-hover scale-105;
}

.product:hover h2 {
  @apply text-lime-eronor text-center
  /* Couleur du texte au survol, ajuste selon tes besoins */
  ;
}

.product:active {
  @apply bg-slate-hover;
}

.catalog {
  @apply grid grid-cols-2 gap-2 my-2;
}

.product .text-content {
  @apply flex flex-col text-center w-full p-1 text-sm;
}

.text-content h2 {
  @apply uppercase font-semibold;
}

@media screen and (min-width: 600px) {
  .placeholder-product {
    @apply grid-cols-3;
  }

  .catalog {
    @apply grid-cols-3;
  }
}

@media screen and (min-width: 800px) {
  .product {
    @apply pb-5 max-h-60 max-w-60;
  }

  .catalog {
    @apply grid-cols-4 justify-center;
  }

  .placeholder-product {
    @apply grid-cols-4 justify-center;
  }

  .catalog-product {
    @apply mx-4;
  }

}

@media screen and (min-width: 1020px) {
  .aside-div-category {
    @apply flex flex-col items-start gap-2 mt-2;
    min-width: 160px;
    max-width: 160px;
  }

  .section-mobile-first {
    @apply flex-1 ml-4;
  }

  .aside-category-item {
    @apply flex flex-col items-center bg-slate-eronor rounded-md;
    width: 100%;
    transition: transform 0.3s ease;
  }

  .category-isactive {
    @apply scale-105 bg-slate-hover
  }

  .aside-section {
    @apply flex;
  }

  .catalog {
    @apply grid grid-cols-4 gap-3;
  }

  .placeholder-product {
    @apply grid-cols-4;
  }

  .catalog-product {
    @apply mx-8;
  }
}

@media screen and (min-width: 1280px) {
  .aside-category-item:hover {
    @apply bg-slate-hover;
  }

}

@media screen and (min-width: 1500px) {
  .catalog {
    @apply grid grid-cols-5 gap-4;
  }

  .placeholder-product {
    @apply grid-cols-5;
  }

  .catalog-product {
    @apply mx-auto container;
  }
}
</style>
