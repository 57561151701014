<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from 'vue'
import Section from '@/components/main/Section.vue'
import SectionContact from '@/components/main/SectionContact.vue'
import SectionHours from '@/components/main/SectionHours.vue'
import stores from '@/storage/stores'


const nameStore = ref<string>('Eronor.com')

watch(
  () => stores.state.storeData,
  () => {

    nameStore.value = stores.state.storeData.name || 'Eronor.com'
  },
  { immediate: true }
)
</script>

<template>
  <footer class="" :class="{

  }">
    <div class="middle-footer">
      <SectionContact :nameStore="nameStore" title="Contact" />
      <Section title="Informations du site" />
      <SectionHours title="Horaires / Livraison" />
    </div>
  </footer>
</template>

<style scoped>
footer {
  @apply w-full pt-4;
}

.middle-footer {
  @apply flex flex-col flex-grow bg-black w-full bottom-0 p-2 min-h-full;
  @apply smoke-white:text-white
}

@media screen and (min-width: 800px) {
  .middle-footer {
    @apply grid grid-cols-3 justify-items-center mx-auto;
  }
}
</style>
