<script setup lang="ts">
const props = defineProps<{ function: () => void }>()

const toggle = () => {
  props.function()
}
</script>

<template>
  <button class="button" @click="toggle">
    <svg class="svg-cross" viewBox="0 0 30 30" fill="" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_688_4815)">
        <path
          d="M0 27.2719L2.72813 30L15 17.7281L27.2719 30L30 27.2719L17.7281 15L30 2.72813L27.2719 0L15 12.2719L2.72813 0L0 2.72813L12.2719 15L0 27.2719Z"
          fill=""
        />
      </g>
      <defs>
        <clipPath id="clip0_688_4815">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </button>
</template>

<style scoped>
.button:hover .svg-cross {
  @apply fill-black;
}
.svg-cross {
  @apply w-6 p-1 fill-white-eronor;
}
</style>
