<script setup lang="ts">
import IconArrowBottom from '@/components/icons/iconEronor/IconArrowBottom.vue';
import { showDropdownSidebarCategory } from '@/event/main/navbar/showDropdownSidebarCategory';
import { menuVisibleBus } from '@/event/menuVisibleBus';
import { theme } from '@/event/theme/theme';
import { currentIndexCarousel } from '@/firstTheme/event/carousel/currentIndexCarousel';
import { currentIndexSidebar } from '@/firstTheme/event/sidebar/currentIndexSidebar';
import { getCategoryName } from '@/utils/category/category';
import { catalogueBase } from '@/utils/main/navbar/statesNavbar';
import { toggleDropdownSidebarCategory } from '@/utils/main/navbar/toggleDropdownSidebarCategory';
import { setTheme } from '@/utils/theme/setTheme';
import { toggleMenu } from '@/utils/toggle/toggleMenu';
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute()
const router = useRouter()

function selectedLinkSidebar(index: number) {
    menuVisibleBus.value = false

    currentIndexCarousel.value = index
    currentIndexSidebar.value = index
    if (route.name !== 'racine') {
        router.push('/')
    }
}

</script>

<template>
    <div class="sidebar-smoke"
        :class="{ 'is-active': menuVisibleBus && (theme === 'smoke' || theme === 'smoke-white') }">
        <div class="flex gap-2">
            <button
                class="mt-8 bg-black border smoke:border-white text-white hover:shadow-white hover:shadow-md p-2 rounded-3xl"
                @click="setTheme('smoke')">
                Smoke
            </button>

            <button
                class="mt-8 bg-white border smoke-white:border-black text-black hover:shadow-black hover:shadow-md p-2 rounded-3xl"
                @click="setTheme('smoke-white')">
                Smoke white
            </button>
        </div>

        <div class="close-btn">
            <button class="" @click="toggleMenu">
                <svg class="svg-cross" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16 8L8 16M8.00001 8L16 16" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </button>
        </div>

        <div class="div-link-smoke">
            <button @click="toggleDropdownSidebarCategory" class="flex justify-between w-52">
                <h2 class="h2-smoke-sidebar"
                    :class="{ 'is-active': showDropdownSidebarCategory || currentIndexSidebar !== null }">Catégories
                </h2>
                <IconArrowBottom
                    :class="{ dropdownisactive: showDropdownSidebarCategory || currentIndexSidebar !== null }" />
            </button>

            <div v-if="showDropdownSidebarCategory" class="flex flex-col items-start">
                <button v-for="(category, index) in catalogueBase" :key="index" class="link-sidebar-smoke"
                    :class="{ 'active-link': currentIndexSidebar !== null && currentIndexSidebar === index }"
                    @click="selectedLinkSidebar(index)">

                    {{ getCategoryName(category).toString() }}
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.close-btn {
    @apply w-full flex justify-end items-center;
}

.svg-cross {
    @apply stroke-white smoke-white:stroke-black w-10 hover:stroke-lime-eronor
}

.div-link-smoke {
    @apply w-full relative flex flex-col py-4 items-start font-medium;
}

.sidebar-smoke {
    @apply smoke:bg-black smoke-white:bg-white border-r smoke-white:border-black flex flex-col items-center fixed py-3 px-3 top-0 left-0 h-full w-72 z-30 -translate-x-full;
}

.sidebar-smoke.is-active {
    @apply translate-x-0;
}


.h2-smoke-sidebar {
    @apply pb-2 underline;
}

.h2-smoke-sidebar.is-active {
    @apply text-lime-eronor
}

.link-sidebar-smoke {
    @apply overflow-hidden relative;
}

.link-sidebar-smoke:hover {
    @apply text-lime-eronor;
}

.link-sidebar-smoke::after {
    content: '';
    position: absolute;
    background-color: white;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0%;
    transition: width 0.3s ease;
}

.link-sidebar-smoke:hover::after {
    width: 100%;
    @apply bg-lime-eronor;
}

.link-sidebar-smoke.active-link {
    @apply text-lime-eronor;

}

.link-sidebar-smoke.active-link::after {
    width: 100%;

    @apply bg-lime-eronor;

}

.dropdownisactive {
    @apply fill-lime-eronor;
    transform: rotate(180deg);
}
</style>