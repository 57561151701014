import { URL_BACK, URL_IMAGE } from '@/config/constants'
import { cartBus } from '@/event/cartBus'
import { isNavbarVisible } from '@/event/isNavbarVisible'
import { sectionOrderBus } from '@/event/sectionOrderBus'
import { theme } from '@/event/theme/theme'
import { baseUrl, firstPartUrl } from '@/event/url/url'
import { configMetaTitle } from '@/storage/setting/configMetaTitle'
import stores from '@/storage/stores'
import { fetchCsrf } from './csrf/fetchCsrf'

export async function getStore() {
  let response: Response
  let csrfToken: { value: string } = { value: '' }
  try {
    await fetchCsrf(csrfToken)
    const url =
      firstPartUrl === 'http'
        ? `${firstPartUrl}://127.0.0.1:8080/api/store/search_url`
        : `${firstPartUrl}://${URL_BACK}/api/store/search_ssl`

    response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': csrfToken.value,
        'Content-Type': 'application/json',
        Accept: '*/*'
      },
      body: JSON.stringify({ url: baseUrl })
    })

    if (!response.ok) {
      throw new Error('Failed to fetch store data')
    }
    const storeData = await response.json()

    if (storeData && !storeData.banner_images) {
      isNavbarVisible.value = true
      if (cartBus.value.length > 0) {
        sectionOrderBus.value = true
      }
    }

    if (storeData && storeData.setting) {
      const openingHoursSetting = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_opening_hours'
      )

      if (
        openingHoursSetting &&
        typeof openingHoursSetting.value === 'string' &&
        openingHoursSetting.value.length > 0
      ) {
        const openingHoursString = openingHoursSetting.value
        const openingHoursJson = JSON.parse(openingHoursString)
        if (openingHoursJson) {
          storeData.setting.find(
            (setting: { key: string }) => setting.key === 'config_opening_hours'
          ).value = openingHoursJson
        }
      }

      const deliveryZonesSetting = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_delivery_zones'
      )
      if (
        deliveryZonesSetting &&
        typeof deliveryZonesSetting.value === 'string' &&
        deliveryZonesSetting.value.length > 0
      ) {
        const deliveryZonesJson = JSON.parse(deliveryZonesSetting.value)
        if (deliveryZonesJson) {
          storeData.setting.find(
            (setting: { key: string }) => setting.key === 'config_delivery_zones'
          ).value = deliveryZonesJson
        }
      }

      const deliveryTimeSettings = storeData.setting.find(
        (setting: { key: string }) => setting.key === 'config_delivery_time_settings'
      )

      if (
        deliveryTimeSettings &&
        typeof deliveryTimeSettings.value === 'string' &&
        deliveryTimeSettings.value.length > 0
      ) {
        const deliveryTimeSettingsJson = JSON.parse(deliveryTimeSettings.value)
        if (deliveryTimeSettingsJson) {
          storeData.setting.find(
            (setting: { key: string }) => setting.key === 'config_delivery_time_settings'
          ).value = deliveryTimeSettingsJson
        }
      }
    }

    stores.setStoresData(storeData)

    if (stores.getStoresData().setting?.find((s: { key: string }) => s.key === 'config_theme')) {
      const themeSetting = stores
        .getStoresData()
        .setting?.find((s: { key: string }) => s.key === 'config_theme').value
      const html = document.documentElement

      html.classList.add(themeSetting)
      theme.value = themeSetting
    }
    if (stores.state.storeData.name && stores.state.storeData.setting) {
      document.title = stores.state.storeData.name

      const logoSetting = stores.state.storeData.setting.find(
        (s: { key: string }) => s.key === 'config_logo'
      )
      const iconUrl = logoSetting ? logoSetting.value : undefined

      updateFavicon(iconUrl)
    }
    updateMetaDescription(configMetaTitle.value)
  } catch (e) {
    // add log or send log
  }
}

function updateFavicon(iconUrl: string | undefined) {
  let link = document.getElementById('icon-tab') as HTMLLinkElement
  if (link && iconUrl) {
    link.href = URL_IMAGE + iconUrl
  } else {
    link.href = 'icone_eronor.png'
  }
}
function updateMetaDescription(content: string) {
  const linkDescription = document.getElementById('description') as HTMLMetaElement
  if (linkDescription && content) {
    linkDescription.content = content
  }
}
