<script setup lang="ts">
import { numberBasket } from '@/event/basketBus'
import { showCartBus } from '@/event/cartBus'
import { ref, watch } from 'vue'
import Basket from '@/components/basket/Basket.vue'
import { theme } from '@/event/theme/theme'
import { waitAuhenticated } from '@/storage/account/waitAuthenticated'

watch(
  () => numberBasket.value,
  (newValue) => {
    numberBasket.value = newValue
  }
)

const toggleCartDetails = () => {
  showCartBus.value = !showCartBus.value
}
</script>

<template>
  <section class="relative">
    <button class="cart-button" @click="toggleCartDetails" :disabled="waitAuhenticated == 0">
      <button class="cart-total">
        <p>{{ numberBasket }}</p>
      </button>

      <svg class="svg-basket"
        :class="{ 'svg-basket': theme === 'smoke' || theme === 'default', 'svg-basket-smoke-white': theme === 'smoke-white' }"
        viewBox="0 0 512 440" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M256 219.989C261.52 219.989 266 215.508 266 209.989C266 204.469 261.52 199.989 256 199.989C250.48 199.989 246 204.469 246 209.989C246 215.508 250.48 219.989 256 219.989Z" />
        <path
          d="M472 139.989H412.863L321.895 14.8364C313.723 0.832482 295.723 -4.15189 281.426 3.76608C266.934 11.8169 261.723 30.0708 269.777 44.5669C270.008 44.977 270.262 45.3715 270.547 45.7465L341.898 139.989H170.102L241.453 45.7465C241.734 45.3715 241.992 44.977 242.223 44.5669C250.258 30.1059 245.105 11.8364 230.562 3.75826C216.297 -4.14408 198.297 0.83639 190.109 14.8286L99.1367 139.989H40C17.9453 139.989 0 157.934 0 179.989C0 197.383 11.2891 212.528 27.1914 217.887C28.8867 219.2 31.0039 219.989 33.3086 219.989C33.7695 219.989 34.2031 220.016 34.6562 220.079C38.9609 220.657 42.3711 223.922 43.1523 228.196L77.1719 415.36C79.7695 429.629 92.1836 439.989 106.691 439.989H405.309C419.816 439.989 432.23 429.629 434.828 415.356L468.848 228.2C469.629 223.922 473.043 220.657 477.363 220.079C477.801 220.016 478.234 219.989 478.691 219.989C481.012 219.989 483.145 219.192 484.84 217.864C500.754 212.469 512 197.352 512 179.989C512 157.934 494.055 139.989 472 139.989ZM286.988 34.3286C284.703 29.5981 286.477 23.8364 291.125 21.2583C295.965 18.5747 302.066 20.3051 304.734 25.1137C304.93 25.4731 305.152 25.8169 305.391 26.145L388.137 139.989H366.98L286.988 34.3286ZM206.609 26.1489C206.848 25.8208 207.062 25.4809 207.262 25.1294C209.937 20.3169 216.043 18.5825 220.863 21.2505C225.52 23.8364 227.301 29.5903 225.012 34.3286L145.02 139.989H123.863L206.609 26.1489ZM472 199.989H296C290.477 199.989 286 204.465 286 209.989C286 215.512 290.477 219.887 296 219.887H450.398C449.875 221.379 449.461 222.926 449.172 224.52L415.148 411.778C414.285 416.536 410.145 419.989 405.309 419.989H106.691C101.852 419.989 97.7148 416.536 96.8477 411.782L62.8281 224.618C62.5391 223.024 62.125 221.477 61.6016 219.989H216C221.523 219.989 226 215.512 226 209.989C226 204.465 221.523 199.989 216 199.989H40C28.8789 199.989 20 190.926 20 179.989C20 168.961 28.9727 159.989 40 159.989H472C483.027 159.989 492 168.961 492 179.989C492 191.094 482.914 199.989 472 199.989Z" />
        <path
          d="M256 249.989C239.457 249.989 226 263.446 226 279.989V359.989C226 376.532 239.457 389.989 256 389.989C272.543 389.989 286 376.532 286 359.989V279.989C286 263.415 272.574 249.989 256 249.989ZM266 359.989C266 365.504 261.516 369.989 256 369.989C250.484 369.989 246 365.504 246 359.989V279.989C246 274.473 250.484 269.989 256 269.989C261.52 269.989 266 274.469 266 279.989V359.989Z" />
        <path
          d="M356 389.989C372.543 389.989 386 376.532 386 359.989V279.989C386 263.415 372.574 249.989 356 249.989C339.457 249.989 326 263.446 326 279.989V359.989C326 376.532 339.457 389.989 356 389.989ZM346 279.989C346 274.473 350.484 269.989 356 269.989C361.52 269.989 366 274.469 366 279.989V359.989C366 365.504 361.516 369.989 356 369.989C350.484 369.989 346 365.504 346 359.989V279.989Z" />
        <path
          d="M156 249.989C139.457 249.989 126 263.446 126 279.989V359.989C126 376.532 139.457 389.989 156 389.989C172.543 389.989 186 376.532 186 359.989V279.989C186 263.415 172.574 249.989 156 249.989ZM166 359.989C166 365.504 161.516 369.989 156 369.989C150.484 369.989 146 365.504 146 359.989V279.989C146 274.473 150.484 269.989 156 269.989C161.52 269.989 166 274.465 166 279.989V359.989Z" />
      </svg>
    </button>

    <Basket />
  </section>
</template>

<style scoped>
.cart-button {
  @apply mr-2 w-full relative inline-block;
}

.cart-total {
  @apply absolute top-2/4 -right-1 bg-red-eronor rounded-full w-7 h-7 flex items-center justify-center font-bold border-2 border-white-eronor;
  transform: translateY(-50%);
  font-size: 15px;
}

.cart-total p {
  @apply h-full;
}

.svg-basket {
  @apply w-10 fill-white-eronor stroke-white-eronor;
}

.svg-basket:hover {
  @apply fill-limedark-eronor stroke-limedark-eronor;
}

.svg-basket-smoke-white {
  @apply w-10 fill-black
}

.cart-button:hover .svg-basket {
  @apply fill-limedark-eronor stroke-limedark-eronor;

}
</style>
