export const excludeRouteDropdownCategoryArray = ['catalog-product', 'product']
export const excludeRouteShowNavbar = ['error_urlchecker', 'racine']
export const excludeRouteShowSectionOrderBar = [
  'error_urlchecker',
  'register',
  'confirm-register',
  'login',
  'forget',
  'basket'
]

export const excludeRouteLoginArray = ['login', 'register', 'confirm-register', 'forget']

export const excludePathDisconnectArray = ['dashboard', 'basket-order']
export const excludeRouteApp = ['register', 'login', 'confirm-register']
