<script setup lang="ts">
import DashboardHistory from '@/components/dashboard/DashboardHistory.vue'
import ModalHistory from '@/components/dashboard/modal/modalHistory.vue'
import SideBarDashboard from '@/components/dashboard/SideBarDashboard.vue'
import { selectedOrder } from '@/event/account/dashboard/history/selectedOrder'
import { defineAsyncComponent } from 'vue';
const Ariane = defineAsyncComponent(() => import('@/components/ariane/Ariane.vue'));
</script>

<template>
  <section class="section-dashboard-order">
    <Ariane />
    <ModalHistory v-if="selectedOrder" class="" />

    <section class="section-content-dashboard-history">
      <div class="main-content-history-order">
        <h3 class="text-h3-history">Historique de commandes</h3>

        <DashboardHistory />
      </div>

      <SideBarDashboard />
    </section>
  </section>
</template>

<style scoped>
.section-dashboard-order {
  @apply px-3 w-full pb-16 relative flex flex-col items-center;
}

.section-content-dashboard-history {
  @apply flex flex-col w-full relative;
}

.main-content-history-order {
  @apply w-full flex flex-col justify-center;
}

.text-h3-history {
  @apply text-lg font-semibold pb-3;
}

@media screen and (min-width: 700px) {
  .section-content-dashboard-history {
    @apply flex-row justify-between gap-4;
  }
}

@media screen and (min-width: 900px) {
  .section-dashboard-order {
    @apply w-[55rem];
  }
}

@media screen and (min-width: 1200px) {
  .section-dashboard-order {
    @apply w-[70rem];
  }
}
</style>
