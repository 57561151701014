// index.ts

import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/Page/HomeView.vue'
import ErrorLoadUrl from '@/views/error/ErrorLoadUrl.vue'
import ProductView from '@/views/Page/ProductView.vue'
import CatalogProductView from '@/views/Page/CatalogProductView.vue'
import BasketView from '@/views/Page/BasketView.vue'
import DashboardView from '@/views/Page/dashboard/DashboardView.vue'
import DashboardOrderView from '@/views/Page/dashboard/DashboardOrderView.vue'
import OrderView from '@/views/Page/OrderView.vue'
import { dataLoaded } from '@/event/dataLoaded'
import { linkGoOrder } from '@/event/basket/linkGoOrder'

const LoginView = () => import('@/views/Page/LoginView.vue')
const RegisterView = () => import('@/views/Page/RegisterView.vue')
const ConfirmRegisterView = () => import('@/views/Page/ConfirmRegisterView.vue')
const ForgetView = () => import('@/views/Page/ForgetView.vue')
const DashboardEditView = () => import('@/views/Page/dashboard/DashboardEditView.vue')
const DashboardPasswordView = () => import('@/views/Page/dashboard/DashboardPasswordView.vue')
const DashboardAddressView = () => import('@/views/Page/dashboard/DashboardAddressView.vue')
const DashboardTransactionView = () => import('@/views/Page/dashboard/DashboardTransactionView.vue')
const DashboardRecurringView = () => import('@/views/Page/dashboard/DashboardRecurringView.vue')
const CancelView = () => import('@/views/Page/order/CancelView.vue')
const SuccessView = () => import('@/views/Page/order/SuccessView.vue')

const routes = [
  {
    path: '/',
    name: 'racine',
    component: HomeView
  },
  {
    path: '/cproduct/:category_id/:category_name',
    name: 'catalog-product',
    component: CatalogProductView
  },
  { path: '/product/:category_id/:product_id', name: 'product', component: ProductView },
  { path: '/basket', name: 'basket', component: BasketView },
  { path: '/error_urlchecker', name: 'error_urlchecker', component: ErrorLoadUrl },
  { path: '/login', name: 'login', component: LoginView },
  { path: '/register', name: 'register', component: RegisterView },
  {
    path: '/confirm-register/:token',
    name: 'confirm-register',
    component: ConfirmRegisterView
  },
  { path: '/forget/:token', name: 'forget', component: ForgetView },
  { path: '/dashboard', name: 'dashboard', component: DashboardView },
  { path: '/dashboard/edit', name: 'dashboard-edit', component: DashboardEditView },
  { path: '/dashboard/password', name: 'dashboard-password', component: DashboardPasswordView },
  { path: '/dashboard/address', name: 'dashboard-address', component: DashboardAddressView },
  { path: '/dashboard/order', name: 'dashboard-order', component: DashboardOrderView },
  {
    path: '/dashboard/transaction',
    name: 'dashboard-transaction',
    component: DashboardTransactionView
  },
  { path: '/dashboard/recurring', name: 'dashboard-recurring', component: DashboardRecurringView },
  { path: '/basket/order', name: 'basket-order', component: OrderView },
  { path: '/success-payment/:order_id', name: 'success-payment', component: SuccessView },
  { path: '/cancel-payment/:order_id', name: 'cancel-payment', component: CancelView },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: () => '/'
  }
]

const router = createRouter({
  // history: createMemoryHistory(),
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (dataLoaded.value == undefined && to.name === 'error_urlchecker') {
    next({ path: '/' })
  } else {
    next()
  }

  if (from.name == 'basket' && to.name == 'login') {
    linkGoOrder.value = true
  }
})

export default router
