<script setup lang="ts">
import { showClearBasketBus } from '@/event/basket/clearBasketBus'
import ButtonValidate from '@/components/button/ButtonValidate.vue'
import { cartBus } from '@/event/cartBus'
import ButtonCloseProduct from '@/components/button/ButtonCloseProduct.vue'

const toggleClearBasket = () => {
  showClearBasketBus.value = !showClearBasketBus.value
}

const clearBasket = () => {
  cartBus.value.splice(0, cartBus.value.length)
  showClearBasketBus.value = !showClearBasketBus.value
}
</script>

<template>
  <section v-if="showClearBasketBus" class="w-full flex justify-center relative">
    <div class="basket-delete-overlay" @click="toggleClearBasket"></div>
    <div class="alert-clear-basket">
      <p>Voulez-vous vider votre panier</p>
      <div class="button-clear">
        <ButtonCloseProduct :function="toggleClearBasket" />
        <ButtonValidate :function="clearBasket" />
      </div>
    </div>
  </section>
</template>

<style scoped>
.basket-delete-overlay {
  @apply fixed top-0 left-0 w-full h-screen flex content-center items-center z-40 opacity-90;
  background-color: rgba(0, 0, 0, 0.5);
}

.alert-clear-basket {
  @apply bg-red-eronor fixed top-36 z-50 p-2 rounded-md min-w-64 flex flex-col items-center;
}

.button-clear {
  @apply flex justify-between px-8 mt-4 mb-1 w-full;
}

/* .button-clear button {
  @apply bg-red-hover-eronor p-1 rounded-md hover:bg-reddark-eronor;
} */
</style>
