import { ref } from 'vue'
import { fetchCsrf } from '../csrf/fetchCsrf'
import { URL_BACK } from '@/config/constants'
import stores from '@/storage/stores'
import { formatStoreName } from '../store/formatStoreName'
import customer from '@/storage/customer'

const csrfToken = ref()

export async function fetchPostAuthorization(urlEnd: string, data: any) {
  const store = stores.state.storeData.name
  let token
  if (store) {
    const storeName = formatStoreName(store)
    token = localStorage.getItem(`${storeName}_jwt`)
  }

  try {
    await fetchCsrf(csrfToken)

    const url = `https://${URL_BACK}/api/${urlEnd}`

    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.value
      },
      body: JSON.stringify(data)
    })
    const response = await res.json()
    if (response.user) customer.setCustomerData(response.user)

    return response
  } catch (e) {}
}
