export function getProductTax(productPrice: any, tax: any) {
  const price = parseFloat(productPrice as string)
  const taxRt = parseFloat(tax as string) / 100
  if (price === 0) {
    return 0
  }

  const finalTax = (productPrice * taxRt) / (1 + taxRt)

  return finalTax
}
