<script setup lang="ts">
import { showValidateOrderModal } from '@/event/basket/showValidateOrderModal'
import { toggleValidateOrderModal } from '@/utils/toggle/basket/toggleValidateOrderModal'
import IconBasket from '../icons/iconEronor/IconBasket.vue'
import ButtonClose from '../button/ButtonClose.vue'
import { toggleCartDetails } from '@/utils/toggle/basket/toggleCartDetails'
import { defineAsyncComponent } from 'vue'

const ButtonLogin = defineAsyncComponent(() => import('@/components/button/account/ButtonLogin.vue'));
const ButtonLinkRegister = defineAsyncComponent(() => import('@/components/button/account/ButtonLinkRegister.vue'));

function toggleLoginOrRegister() {
  toggleValidateOrderModal()
  toggleCartDetails()
}
</script>

<template>
  <section v-if="showValidateOrderModal" class="section-validate-order">
    <div class="validate-order">
      <div class="absolute top-4 right-3">
        <ButtonClose :function="toggleValidateOrderModal" />
      </div>
      <IconBasket />
      <p class="text-center">Vous devez être connecté pour commander</p>
      <div class="div-button-register">
        <ButtonLinkRegister @click="toggleLoginOrRegister" />
        <ButtonLogin @click="toggleLoginOrRegister" />
      </div>
    </div>
  </section>
</template>

<style scoped>
.section-validate-order {
  @apply absolute flex justify-center mx-1 mt-20 z-50;
}

.validate-order {
  @apply relative flex flex-col items-center justify-center gap-3 bg-slate-eronor p-3 min-w-72 rounded-lg;
}

.div-button-register {
  @apply flex w-full justify-between p-1;
}
</style>
