import { URL_BACK } from '@/config/constants'
import { firstPartUrl } from '@/event/url/url'

export async function fetchCsrf(csrfToken: { value: string }) {
  let response
  const urlCsrf =
    firstPartUrl === 'http'
      ? `${firstPartUrl}://127.0.0.1:8080/api/csrf-token`
      : `${firstPartUrl}://${URL_BACK}/api/csrf-token`

  try {
    const responseCsrf = await fetch(urlCsrf, { method: 'GET', credentials: 'include' })
    response = await responseCsrf.json()
    csrfToken.value = response.csrf_token
  } catch (e) {
    return
  }
}
