import { URL_BACK } from '@/config/constants'
import { firstPartUrl } from '@/event/url/url'
import products from '@/storage/products'
import stores from '@/storage/stores'
import { fetchCsrf } from './csrf/fetchCsrf'

export async function getProductToCategory(categoryIdArray: Array<number>) {
  let responseProduct: Response
  let csrfToken: { value: string } = { value: '' }
  try {
    await fetchCsrf(csrfToken)
    const url =
      firstPartUrl === 'http'
        ? `${firstPartUrl}://127.0.0.1:8080/api/product/search_product`
        : `${firstPartUrl}://${URL_BACK}/api/product/search_product`

    responseProduct = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': csrfToken.value,
        'Content-Type': 'application/json',
        Accept: '*/*'
      },
      body: JSON.stringify({
        category_id_array: categoryIdArray,
        store_id: stores.state.storeData.store_id,
        store_name: stores.state.storeData.name
      })
    })

    const fetchData = await responseProduct.json()
    products.setProductData(fetchData)
  } catch (e) {
    throw e
  }
}
