<script setup lang="ts">
// OrderView.vue
import MyInformationOrder from '@/components/account/order/MyInformationOrder.vue'
import OptionOrder from '@/components/account/order/OptionOrder.vue'
import SelectHoursOrder from '@/components/account/order/SelectHoursOrder.vue'
import ValidateOrder from '@/components/account/order/ValidateOrder.vue'
import Alert from '@/components/alert/Alert.vue'
import { messageAddAddress } from '@/event/account/dashboard/messageAddAddress'
import { cartBus } from '@/event/cartBus'
import {
  orderData,
  updateFieldOrderData,
  type OrderDataProductsOptionType,
  type OrderDataProductsType
} from '@/event/order/data/orderData'
import { messageSend } from '@/event/order/messageSend'
import { showDeliveryZones } from '@/event/order/showDeliveryZones'
import { configCheckoutRestaurant } from '@/storage/setting/configCheckoutRestaurant'
import customer from '@/storage/customer'
import { paymentStripeproStatus } from '@/storage/paymentStripeproStatus'
import stores from '@/storage/stores'
import { submitOrder } from '@/utils/order/submitOrder'
import { convertPriceToEuro } from '@/utils/price/convertPrice'
import { totalPrice } from '@/utils/price/totalPrice'
import { toggleAddAddress } from '@/utils/toggle/dashboard/toggleAddAddress'
import { computed, defineAsyncComponent, ref, watch } from 'vue'

const AlertDeliveryZones = defineAsyncComponent(() => import('@/components/account/order/AlertDeliveryZones.vue'));
const Ariane = defineAsyncComponent(() => import('@/components/ariane/Ariane.vue'));
const PaymentOrder = defineAsyncComponent(() => import('@/components/account/order/PaymentOrder.vue'));
const ButtonOrderSend = defineAsyncComponent(() => import('@/components/button/order/ButtonOrderSend.vue'));
const ButtonPaymentOrder = defineAsyncComponent(() => import('@/components/button/order/ButtonPaymentOrder.vue'));
const Payment = defineAsyncComponent(() => import('@/components/button/order/Payment.vue'));


const currentStep = ref('step0')
const takeOrder = ref(false)
const deliveryOrder = ref(false)
const finalStep = ref(true)

const userAgent = navigator.userAgent

let methodPayment: any

const handleContinue = (eventData: any) => {
  if (['step0', 'step1', 'step2', 'step3', 'step4'].includes(eventData.step)) {
    currentStep.value = eventData.step
    finalStep.value = true
  }

  if (eventData.step == 'step5') {
    finalStep.value = false
    updateFieldOrderData({ user_agent: userAgent })
    if (methodPayment === 'Card') {
      takeOrder.value = false
    } else {
      deliveryOrder.value = false
    }
  }

  if (eventData.method_payment === 'Card') {
    deliveryOrder.value = true
    methodPayment = 'Card'
    takeOrder.value = false
    updateFieldOrderData({ order_status: 0 })
  } else if (eventData.method_payment === 'Counter') {
    deliveryOrder.value = false
    takeOrder.value = true
    methodPayment = 'Counter'
    updateFieldOrderData({ order_status: 1 })
  }
}


const canPlaceOrder = computed(() => {
  if (stores.state.storeData.status === 1) {
    return true;
  }
  if (stores.state.storeData.status === 2 && customer.state.customerData.status === 2) {
    return true;
  }
  return false;
});
</script>

<template>
  <section class="section-basket-order">
    <Alert :function="toggleAddAddress" :message="messageAddAddress" />
    <AlertDeliveryZones v-if="showDeliveryZones" />
    <Ariane />

    <section class="section-content-basket-order">
      <div class="div-content-basket-order">
        <h3 class="h3-validate-order">Validation de commande</h3>
        <p class="text-rising">
          Montant de la commande:
          <span class="text-amber-eronor">{{ convertPriceToEuro(totalPrice) }}€</span>
        </p>
      </div>

      <p v-if="paymentStripeproStatus === '0' && configCheckoutRestaurant === '0'"
        class="text-center bg-red-eronor rounded-md py-1">
        Les moyens de paiements ne sont pas disponible !
      </p>
      <p v-if="!canPlaceOrder" class="text-center bg-red-eronor rounded-md py-1">
        Le site est une démo vous ne pourrait pas passer commande
      </p>

      <MyInformationOrder :isOpenInitial="currentStep" @continue="handleContinue" />
      <OptionOrder :isOpenInitial="currentStep" @continue="handleContinue" />
      <SelectHoursOrder :isOpenInitial="currentStep" @continue="handleContinue" />
      <PaymentOrder :isOpenInitial="currentStep" @continue="handleContinue" />
      <ValidateOrder :isOpenInitial="currentStep" @continue="handleContinue" />
    </section>

    <section class="section-button-payment" v-if="canPlaceOrder">
      <!-- <ButtonPaymentOrder v-if="!finalStep && deliveryOrder" :finishStep="finalStep && cartBus.length > 0"
        @click="submitOrder" /> -->
      <ButtonOrderSend v-if="!finalStep && takeOrder && cartBus.length > 0" @click="submitOrder" />
      <Payment v-if="!finalStep && deliveryOrder && cartBus.length > 0" class="section-payment-stripe-element" />
    </section>
    <p v-if="messageSend.message" :class="messageSend.class">{{ messageSend.message }}</p>
  </section>
</template>

<style scoped>
.success {
  @apply flex flex-col bg-lime-eronor px-3 pt-1 pb-1 mt-2 w-full text-center rounded-md;
}

.error {
  @apply flex flex-col bg-red-eronor px-3 pt-1 pb-1 mt-2 w-full text-center rounded-md;
}

.section-basket-order {
  @apply px-3 w-full flex flex-col items-center pb-12 relative;
}

.div-content-basket-order {
  @apply border-b border-b-slate-eronor flex flex-col justify-between items-center p-1 pb-3;
}

.h3-validate-order {
  @apply font-semibold text-lg;
}

.text-rising {
  @apply pt-1;
}

.section-button-payment {
  @apply pt-3 w-full flex items-center justify-center
}

.section-content-basket-order {
  @apply pt-2 w-full flex flex-col gap-4;
}

.section-payment-stripe-element {
  @apply flex flex-col min-w-full items-center justify-center gap-2
}

@media screen and (min-width: 800px) {
  .section-payment-stripe-element {
    @apply min-w-[50rem]
  }
}

@media screen and (min-width: 700px) {

  /* .section-content-dashboard-history {
    @apply flex-row justify-between gap-4;
  } */
  .div-content-basket-order {
    @apply flex-row px-4;
  }
}

@media screen and (min-width: 900px) {
  .section-basket-order {
    @apply w-[55rem];
  }
}

@media screen and (min-width: 1200px) {
  .section-basket-order {
    @apply w-[70rem];
  }
}
</style>
