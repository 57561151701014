<script setup lang="ts">
import { computed, ref } from 'vue'
import IconPlus from '@/components/icons/iconEronor/IconPlus.vue'
import IconSubstract from '@/components/icons/iconEronor/IconSubstract.vue'

const props = defineProps<{ quantity: number; index: number }>()
const emit = defineEmits(['update:quantity'])

const quantity = computed({
  get: () => props.quantity,
  set: (value) => emit('update:quantity', value, props.index)
})

const increment = () => {
  quantity.value++
}

const decrement = () => {
  if (quantity.value > 1) {
    quantity.value--
  }
}
</script>

<template>
  <div class="section-quantity">
    <h4>Quantités</h4>
    <div class="div-quantity">
      <button @click="decrement" class="btn-quantity">
        <p>
          <IconSubstract />
        </p>
      </button>
      <input type="text" name="quantity" v-model="quantity" class="" disabled />
      <button @click="increment" class="btn-quantity">
        <IconPlus />
      </button>
    </div>
  </div>
</template>

<style scoped>
.quantity-input {
  @apply w-8;
}

.section-quantity h4 {
  @apply text-xl ml-2 underline;
}

.div-quantity {
  @apply flex items-center gap-2 m-1;
}


.div-quantity input {
  @apply w-8 bg-transparent font-semibold text-3xl text-center align-middle;
}

.btn-quantity {
  @apply flex justify-center items-center align-middle h-7 w-7 bg-white-eronor text-black font-bold text-xl rounded-3xl;
}

.btn-quantity:hover {
  @apply bg-limedark-eronor text-white-eronor shadow-md;
}
</style>
